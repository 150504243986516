import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../providers/ProjectContext";
import { Pagination, Form, FormControl, Dropdown } from "react-bootstrap";
import { Navbar } from "react-bootstrap";

function ProjectsList() {
  const navigate = useNavigate();
  const { projectList, setCurrentProject } = useContext(ProjectContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(10);

  const handleProjectClick = (project) => {
    setCurrentProject(project);
    navigate("/assets-list");
  };

  // Filtered list based on search term
  const filteredProjects = projectList.filter((project) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesName = project.name.toLowerCase().includes(searchLower);
    const matchesCode = project.code.toLowerCase().includes(searchLower);
    const matchesClientName =
      project.client && project.client.name.toLowerCase().includes(searchLower);

    return matchesName || matchesCode || matchesClientName;
  });

  // Get current projects
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle change in projects per page
  const handleProjectsPerPageChange = (e) => {
    setProjectsPerPage(Number(e));
    setCurrentPage(1);
  };

  // Handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Render pagination with limited visibility
  const renderPagination = () => {
    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 || // First page
        number === totalPages || // Last page
        (number >= currentPage - 1 && number <= currentPage + 1) // Nearby pages
      ) {
        paginationItems.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      } else if (
        number === currentPage - 2 || // Ellipsis before current page
        number === currentPage + 2 // Ellipsis after current page
      ) {
        paginationItems.push(
          <Pagination.Ellipsis key={`ellipsis-${number}`} disabled />
        );
      }
    }

    return (
      <Pagination className="justify-content-start mb-0">
        {paginationItems}
        <Pagination.First onClick={() => paginate(1)} />
        <Pagination.Prev onClick={() => paginate(currentPage - 1)} />
        <Pagination.Next onClick={() => paginate(currentPage + 1)} />
        <Pagination.Last onClick={() => paginate(totalPages)} />
      </Pagination>
    );
  };

  return (
    <div className="bg-white shadow-md w-full">
      <Navbar className="shadow-sm w-full bg-white" expand="lg" fixed="top">
        <div className="pb-1 pt-20 w-full px-6">
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl font-bold mb-4">All Projects</h2>
          </div>
          <div className="flex flex-row justify-between items-center mb-4">
            <div className="flex justify-between items-center w-full">
              <Form className="w-[80%]">
                <FormControl
                  type="text"
                  placeholder="Search Projects"
                  className="mr-sm-2"
                  onChange={handleSearchTermChange}
                />
              </Form>
            </div>
            <div className="flex flex-row gap-3 align-middle items-center">
              {renderPagination()}
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {projectsPerPage}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[10, 20, 30, 40, 50].map((number) => (
                    <Dropdown.Item
                      as="button"
                      key={number}
                      onClick={() => handleProjectsPerPageChange(number)}
                    >
                      {number}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div>Per Page</div>
            </div>
          </div>
        </div>
      </Navbar>
      <div className="px-6 pb-6 mt-60">
        {currentProjects.length > 0 ? (
          <ul className="list-none">
            {currentProjects.map((project, index) => (
              <li
                key={index}
                className="cursor-pointer p-2 hover:bg-gray-200 flex justify-between items-center"
                onClick={() => handleProjectClick(project)}
              >
                <div>
                  <span className="font-semibold">{project.code}</span> -{" "}
                  <span>{project.name}</span>
                  <div className="text-sm text-gray-600">
                    {project.client ? project.client.name : "No Client"}
                  </div>
                </div>
                <div>
                  {project.libraryItems ? project.libraryItems.length : 0} Items
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="italic">There are no projects.</div>
        )}
      </div>
    </div>
  );
}

export default ProjectsList;
