import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ProjectContext } from "../providers/ProjectContext";
// import Select from "react-select";
import CustomSelect from "./CustomSelect";
import DatePicker from "react-datepicker";
import ErrorText from "./ErrorText";
import { createAsset, editAsset } from "../services/apiService";
import { Alert, Spinner } from "react-bootstrap";

const assetValidationSchema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  mediaFormat: Yup.array()
    .of(Yup.string().required("Media format is required"))
    .min(1, "Media format is required")
    .required("Media format is required"),
  length: Yup.string(),
  aspectRatio: Yup.array(),
  audioFormat: Yup.array(),
  storageLocation: Yup.string().required("Storage location is required"),
  notes: Yup.string(),
  producer: Yup.string().required("Producer is required"),
  keyNumber: Yup.string(),
  archiveId: Yup.string(),
  vandalId: Yup.string().required("Vandal ID is required"),
  workOrder: Yup.string(),
  project: Yup.string().required("Project is required"),
  product: Yup.string().required("Product is required"),
  title: Yup.string().required("Title is required"),
  operatorArchivist: Yup.string().required("Operator/Archivist is required"),
  client: Yup.string().required("Client is required"),
  finishingSuite: Yup.string(),
  dateProduced: Yup.date().required("Date produced is required"),
});

function formatOptions(options) {
  return options.map((option) => ({
    value: option,
    label: option.charAt(0).toUpperCase() + option.slice(1),
  }));
}

function formatProjects(projects) {
  return projects.map((project) => ({
    value: project._id,
    label: project.keyNumber + " - " + project.name,
    data: project,
  }));
}

function AssetForm({ asset = null, closeFormModal, duplicating = false }) {
  const { settings, projectList, refreshAll, setCurrentAsset, currentProject } =
    useContext(ProjectContext);

  const effectiveProject = duplicating ? asset.project : currentProject;

  const {
    categories,
    mediaFormats,
    aspectRatios,
    storageLocations,
    audioFormats,
  } = settings;

  const formattedCategories = formatOptions(categories);
  const formattedMediaFormats = formatOptions(mediaFormats);
  const formattedAspectRatios = formatOptions(aspectRatios);
  const formattedStorageLocations = formatOptions(storageLocations);
  const formattedAudioFormats = formatOptions(audioFormats);

  const formattedProjects = formatProjects(projectList);

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedClient, setSelectedClient] = useState({});

  useEffect(() => {
    if (asset && asset.project && asset.project.client) {
      setSelectedClient(asset.client);
      console.log("asc:", asset.client);
    } else if (effectiveProject && effectiveProject.client) {
      setSelectedClient(effectiveProject.client);
      console.log("pjc:", effectiveProject.client);
    }
    // console.log("Selected client:", selectedClient);
    console.log("Current project:", effectiveProject);
    console.log("Asset:", asset);
    console.log("Selected client:", selectedClient);
  }, [effectiveProject, asset, selectedClient]);

  const numberToExcelColumn = (n) => {
    let result = "";
    while (n >= 0) {
      result = String.fromCharCode((n % 26) + 65) + result;
      n = Math.floor(n / 26) - 1;
    }
    return result;
  };

  const generateVandalId = (project) => {
    console.log("caled generateVandalId");
    const libraryItemsCount = project.libraryItems.length;
    const columnLabel = numberToExcelColumn(libraryItemsCount);
    const vandalId = project.code + columnLabel;
    return vandalId;
  };

  const handleProjectChange = (option, setFieldValue) => {
    setFieldValue("project", option.value);
    setFieldValue("client", option.data.client.name);
    setFieldValue("title", option.data.name);
    setFieldValue("product", option.data.name);

    // const libraryItemsCount = option.data.libraryItems.length;
    // const columnLabel = numberToExcelColumn(libraryItemsCount);
    // const vandalId = option.data.code + columnLabel;

    const vandalId = generateVandalId(option.data);
    console.log("vandalId:", vandalId);

    setFieldValue("vandalId", vandalId);
    setTimeout(() => {
      setSelectedClient(option.data.client);
    }, 100);
  };

  const handleCreateAsset = async (assetData, resetForm, setSubmitting) => {
    console.log("Creating asset with data:", assetData);
    try {
      const sendingData = { ...assetData };
      sendingData.client = selectedClient._id;

      const newAsset = await createAsset(sendingData);
      console.log("Asset created successfully:", newAsset);
      setSubmitting(false);
      resetForm();
      refreshAll();
      closeFormModal();
    } catch (error) {
      console.error("Error creating asset:", error);
      setErrorMessage(error.message);
      setShowAlert(true);
    }
  };

  const handleEditAsset = async (
    assetId,
    assetData,
    resetForm,
    setSubmitting
  ) => {
    console.log("Editing asset with data:", assetData);
    try {
      const sendingData = { ...assetData };
      sendingData.client = selectedClient._id;

      const updatedAsset = await editAsset(assetId, sendingData);
      console.log("Asset edited successfully:", updatedAsset);
      setCurrentAsset(updatedAsset);
      setSubmitting(false);
      resetForm();
      refreshAll();
      closeFormModal();
    } catch (error) {
      console.error("Error editing asset:", error);
      setErrorMessage(error.message);
      setShowAlert(true);
    }
  };

  return (
    <>
      {showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      <Formik
        initialValues={{
          category: asset?.category || "",
          mediaFormat: asset?.mediaFormat || [],
          length: asset?.length || "",
          aspectRatio: asset?.aspectRatio || [],
          audioFormat: asset?.audioFormat || [],
          storageLocation: asset?.storageLocation || "",
          notes: "",
          producer: asset?.producer || "",
          keyNumber: duplicating ? "" : asset?.keyNumber || "",
          archiveId: asset?.archiveId || "",
          vandalId:
            asset?.vandalId ||
            (effectiveProject ? generateVandalId(effectiveProject) : ""),
          workOrder: asset?.workOrder || "",
          project: effectiveProject ? effectiveProject._id : "",
          product:
            asset?.product || (effectiveProject ? effectiveProject.name : ""),
          title:
            asset?.title || (effectiveProject ? effectiveProject.name : ""),
          operatorArchivist: asset?.operatorArchivist || "",
          client: asset?.client?.name || effectiveProject?.client?.name || "",
          dateProduced: asset?.dateProduced
            ? new Date(asset.dateProduced)
            : new Date(),
        }}
        validationSchema={assetValidationSchema}
        validateOnMount={true}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          try {
            if (asset && !duplicating) {
              // Call editAsset if asset exists and we are not duplicating
              await handleEditAsset(
                asset._id,
                values,
                resetForm,
                setSubmitting
              );
            } else {
              // Call createAsset if we are duplicating or if the asset is new
              await handleCreateAsset(values, resetForm, setSubmitting);
            }
          } catch (error) {
            console.error("Error in onSubmit:", error);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setTouched,
          errors,
        }) => (
          <Form
            className="container flex flex-col gap-3"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row gap-3 w-full justify-between">
              <div className="flex flex-col gap-3 w-full">
                <div className="form-group">
                  <label htmlFor="keyNumber">Key Number</label>
                  <Field
                    name="keyNumber"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3 w-full">
                <div className="form-group">
                  <label htmlFor="category">Category *</label>
                  <Field
                    name="category"
                    component={CustomSelect}
                    options={formattedCategories}
                    placeholder="Select Category"
                    isMulti={false}
                  />
                  <ErrorMessage name="category" component={ErrorText} />
                </div>
                <div className="form-group">
                  <label htmlFor="dateProduced">Date Produced *</label>
                  <DatePicker
                    selected={values.dateProduced}
                    dateFormat="yyyy-MM-dd"
                    className="form-control w-full"
                    onChange={(date) => setFieldValue("dateProduced", date)}
                    onBlur={handleBlur}
                    name="dateProduced"
                  />
                  <ErrorMessage name="dateProduced" component={ErrorText} />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-3 w-full justify-between">
              <div className="flex flex-col gap-3 w-full">
                <div className="form-group">
                  <label htmlFor="project">Project *</label>
                  <Field
                    name="project"
                    component={CustomSelect}
                    options={formattedProjects}
                    placeholder="Select Project"
                    isMulti={false}
                    isDisabled={effectiveProject != null}
                    onChange={(option, setFieldValue) =>
                      handleProjectChange(option, setFieldValue)
                    }
                  />

                  <ErrorMessage name="project" component={ErrorText} />
                </div>
                <div className="form-group">
                  <label htmlFor="vandalId">Vandal ID *</label>
                  <Field
                    name="vandalId"
                    disabled
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="vandalId" component={ErrorText} />
                </div>
                <div className="form-group">
                  <label htmlFor="workOrder">Work Order</label>
                  <Field
                    name="workOrder"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="storageLocation">Storage Location *</label>
                  <Field
                    name="storageLocation"
                    component={CustomSelect}
                    options={formattedStorageLocations}
                    placeholder="Select Storage Location"
                    isMulti={false}
                  />
                  <ErrorMessage name="storageLocation" component={ErrorText} />
                </div>
              </div>
              <div className="flex flex-col gap-3 w-full">
                <div className="form-group">
                  <label htmlFor="client">Client *</label>
                  <Field
                    name="client"
                    disabled
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="client" component={ErrorText} />
                </div>
                <div className="form-group">
                  <label htmlFor="product">Product *</label>
                  <Field
                    name="product"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="product" component={ErrorText} />
                </div>
                <div className="form-group">
                  <label htmlFor="title">Title *</label>
                  <Field
                    name="title"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="title" component={ErrorText} />
                </div>
                <div className="form-group">
                  <label htmlFor="archiveId">Archive ID</label>
                  <Field
                    name="archiveId"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-3 w-full justify-between">
              <div className="flex flex-col gap-3 w-full">
                <div className="form-group">
                  <label htmlFor="audioFormat">Audio Formats</label>
                  <Field
                    name="audioFormat"
                    component={CustomSelect}
                    options={formattedAudioFormats}
                    placeholder="Select Audio Formats"
                    isMulti={true}
                  />
                  <ErrorMessage name="audioFormat" component={ErrorText} />
                </div>

                <div className="form-group">
                  <label htmlFor="length">Length</label>
                  <Field
                    name="length"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="operatorArchivist">
                    Operator / Archivist *
                  </label>
                  <Field
                    name="operatorArchivist"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="operatorArchivist"
                    component={ErrorText}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="finishingSuite">Finishing Suite</label>
                  <Field
                    name="finishingSuite"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="producer">Producer *</label>
                  <Field
                    name="producer"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="producer" component={ErrorText} />
                </div>
              </div>
              <div className="flex flex-col gap-3 w-full">
                <div className="form-group">
                  <label htmlFor="mediaFormat">Media Format *</label>

                  <Field
                    name="mediaFormat"
                    component={CustomSelect}
                    options={formattedMediaFormats}
                    placeholder="Select Media Formats"
                    isMulti={true}
                  />
                  <ErrorMessage name="mediaFormat" component={ErrorText} />
                </div>

                <div className="form-group">
                  <label htmlFor="aspectRatio">Aspect Ratio</label>
                  <Field
                    name="aspectRatio"
                    component={CustomSelect}
                    options={formattedAspectRatios}
                    placeholder="Select Aspect Ratios"
                    isMulti={true}
                  />
                  <ErrorMessage name="aspectRatio" component={ErrorText} />
                </div>

                <div className="form-group">
                  <label htmlFor="notes">Note</label>
                  <Field
                    name="notes"
                    as="textarea"
                    className="form-control"
                    rows="6"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success mt-3"
              disabled={isSubmitting || !isValid}
            >
              {asset ? "Update Asset" : "Create Asset"}
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AssetForm;
