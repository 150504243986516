import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button, Navbar, Tab, Tabs, Form } from "react-bootstrap";
import AssetForm from "./AssetForm";
import { ProjectContext } from "../providers/ProjectContext";
import {
  fetchAsset,
  addNoteToAsset,
  addHistoryToAsset,
} from "../services/apiService";
import DisplayCreatedFields from "./DisplayCreatedFields";

const AssetView = () => {
  const navigate = useNavigate();

  const { currentAsset, setCurrentAsset } = useContext(ProjectContext);

  const { assetId } = useParams();
  // const [asset, setAsset] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isCheckOutModalOpen, setIsCheckOutModalOpen] = useState(false);
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [isChangelogModalOpen, setIsChangelogModalOpen] = useState(false);

  const [duplicatedAsset, setDuplicatedAsset] = useState({});

  // const [currentAsset, setCurrentAsset] = useState(asset);

  const [noteText, setNoteText] = useState("");
  const [historyFormData, setHistoryFormData] = useState({
    location: "",
    recepient: "",
    deliveredTo: "",
    // dueDate: "",
    company: "",
    phone: "",
    email: "",
    note: "",
  });

  const handleAddNoteSubmit = async (e) => {
    e.preventDefault();
    try {
      await addNoteToAsset(assetId, { note: noteText });
      const updatedAsset = await fetchAsset(assetId);
      setCurrentAsset(updatedAsset);
      setNoteText("");
      setIsNoteModalOpen(false);
    } catch (error) {
      console.error("Failed to add note", error);
    }
  };

  const handleHistoryInputChange = (e) => {
    const { name, value } = e.target;
    setHistoryFormData({ ...historyFormData, [name]: value });
  };

  const handleHistorySubmit = async (e) => {
    e.preventDefault();
    try {
      await addHistoryToAsset(assetId, historyFormData);
      const updatedAsset = await fetchAsset(assetId);
      setCurrentAsset(updatedAsset);
      resetHistoryForm();
      setIsCheckOutModalOpen(false);
    } catch (error) {
      console.error("Failed to add history entry", error);
    }
  };

  const resetHistoryForm = () => {
    setHistoryFormData({
      location: "",
      recepient: "",
      deliveredTo: "",
      // dueDate: "",
      company: "",
      phone: "",
      email: "",
      note: "",
    });
  };

  useEffect(() => {
    const _fetchAsset = async () => {
      try {
        const fetchedAsset = await fetchAsset(assetId);
        setCurrentAsset(fetchedAsset);
      } catch (error) {
        console.error(`Failed to fetch asset ${assetId}`, error);
      }
    };

    _fetchAsset();
  }, [assetId]);

  const backToAssets = () => {
    navigate(-1);
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleDuplicate = () => {
    if (!currentAsset) return;

    const duplicatedAsset = {
      ...currentAsset,
      vandalId: "", // Force new vandalId generation
      keyNumber: "", // Empty key number
      notes: "", // No notes
      history: [], // Clear history
      changeLog: [], // Clear changelog
    };
    console.log(duplicatedAsset);
    setDuplicatedAsset(duplicatedAsset); // A new state to hold the duplicated asset
    setIsDuplicateModalOpen(true); // Open the modal for creating a new asset
  };

  const handleAddNote = () => {
    setIsNoteModalOpen(true);
  };

  const handleCheckOut = () => {
    resetHistoryForm();
    setIsCheckOutModalOpen(true);
  };

  // const handleCheckIn = () => {
  //   setIsCheckInModalOpen(true);
  // };

  const showChangelog = () => {
    setIsChangelogModalOpen(true);
  };

  // const generateBreaks = (count) => {
  //   return Array.from({ length: count }, (_, i) => <br key={i} />);
  // };

  return (
    <>
      <div className="bg-white shadow-md flex rounded p-6">
        <Navbar
          className="shadow-sm flex flex-row w-full bg-white"
          expand="lg"
          fixed="top"
        >
          <div className="pb-1 pt-20 w-full px-6">
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row justify-between gap-4">
                <Button onClick={backToAssets}>Back</Button>
                <h2 className="text-2xl font-bold">
                  {currentAsset && currentAsset.keyNumber
                    ? currentAsset.keyNumber
                    : "#######"}{" "}
                  - {currentAsset ? currentAsset.title : ""}
                </h2>
              </div>
              <div className="flex flex-row justify-between gap-2">
                <Button onClick={handleAddNote}>Add Note</Button>
                {/* <Button onClick={handleCheckIn}>Check In</Button> */}
                <Button onClick={handleCheckOut}>Check Out</Button>
                <Button onClick={showChangelog}>Change Log</Button>
                <Button onClick={handleEdit}>Edit Asset</Button>
                <Button onClick={handleDuplicate}>Duplicate</Button>
              </div>
            </div>
          </div>
        </Navbar>
        {currentAsset ? (
          <div className="flex flex-row justify-between mt-36 w-full gap-2">
            <div className="flex flex-col flex-grow gap-2">
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <span className="text-gray-500 mb-1">Key Number:</span>
                </div>
                <div className="flex flex-col flex-grow">
                  <p>{currentAsset.keyNumber || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Project:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>
                    {currentAsset.project.keyNumber || "#####"} -{" "}
                    {currentAsset.project.name}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Product:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.product}</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Producer:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.producer}</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Work Order:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.workOrder || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Client:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.client.name || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">
                      Operator / Archivist:
                    </span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.operatorArchivist || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Finishing Suite:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.finishingSuite || "-"}</p>
                </div>
              </div>
              <div className="flex-grow"></div>
              <div className="flex flex-row gap-3">
                <DisplayCreatedFields data={currentAsset} />
              </div>
            </div>
            <div className="flex flex-col flex-grow gap-2">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Vandal ID:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.vandalId || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Category:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.category || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">
                      Storage Location:
                    </span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.storageLocation || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Archive ID:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.archiveId || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Length:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{currentAsset.length || "-"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Media Format:</span>
                    {/* {generateBreaks(currentAsset.mediaFormat.length)} */}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>
                    {currentAsset.mediaFormat.length > 0
                      ? currentAsset.mediaFormat.map((format, index) => (
                          <span key={index}>
                            {format}
                            <br />
                          </span>
                        ))
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Aspect Ratio:</span>
                    {/* {generateBreaks(currentAsset.aspectRatio.length)} */}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>
                    {currentAsset.aspectRatio.length > 0
                      ? currentAsset.aspectRatio.map((ratio, index) => (
                          <span key={index}>
                            {ratio}
                            <br />
                          </span>
                        ))
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Audio Format:</span>
                    {/* {generateBreaks(currentAsset.audioFormat.length)} */}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>
                    {currentAsset.audioFormat.length > 0
                      ? currentAsset.audioFormat.map((audio, index) => (
                          <span key={index}>
                            {audio}
                            <br />
                          </span>
                        ))
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col min-w-40">
                  <p>
                    <span className="text-gray-500 mb-1">Masters:</span>
                  </p>
                  <p>
                    <span className="text-gray-500 mb-1">Work:</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>
                    /masters/{currentAsset.project.keyNumber.charAt(0)}0000/
                    {currentAsset.project.keyNumber}_
                    {currentAsset.project.name.replace(/\s+/g, "")}
                  </p>
                  <p>
                    /work/{currentAsset.project.name.replace(/\s+/g, "")}_
                    {currentAsset.project.keyNumber}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/4">
              <div className="flex flex-col flex-grow">
                <Tabs defaultActiveKey="notes" id="uncontrolled-tab-example">
                  <Tab
                    eventKey="notes"
                    title="Notes"
                    className="max-h-fullNoHead overflow-y-auto bg-white shadow-md flex rounded"
                  >
                    <div className="p-4">
                      {currentAsset.notes
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((note, index) => (
                          <div
                            key={index}
                            className="mb-4 p-4 border border-gray-200 rounded shadow-sm"
                          >
                            <p className="whitespace-pre-line">{note.note}</p>
                            <DisplayCreatedFields data={note} />
                          </div>
                        ))}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="history"
                    title="History"
                    className="max-h-fullNoHead overflow-y-auto bg-white shadow-md flex rounded"
                  >
                    <div className="p-4">
                      {currentAsset.history
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((entry, index) => (
                          <div
                            key={index}
                            className="mb-4 p-4 border border-gray-200 rounded shadow-sm"
                          >
                            {entry.location && (
                              <p>
                                <strong>Location:</strong>
                                <br /> {entry.location}
                              </p>
                            )}
                            {entry.recepient && (
                              <p>
                                <strong>Recipient:</strong>
                                <br /> {entry.recepient}
                              </p>
                            )}
                            {entry.deliveredTo && (
                              <p>
                                <strong>Delivered To:</strong>
                                <br /> {entry.deliveredTo}
                              </p>
                            )}
                            {entry.company && (
                              <p>
                                <strong>Company:</strong>
                                <br /> {entry.company}
                              </p>
                            )}
                            {entry.phone && (
                              <p>
                                <strong>Phone:</strong>
                                <br /> {entry.phone}
                              </p>
                            )}
                            {entry.email && (
                              <p>
                                <strong>Email:</strong>
                                <br /> {entry.email}
                              </p>
                            )}
                            {entry.dueDate && (
                              <p>
                                <strong>Due Date:</strong>
                                <br /> {entry.dueDate}
                              </p>
                            )}
                            {entry.note && (
                              <>
                                <p>
                                  <strong>Note:</strong>
                                  <br />
                                  <span className="whitespace-pre-line">
                                    {entry.note}
                                  </span>
                                </p>
                              </>
                            )}
                            <DisplayCreatedFields data={entry} />
                          </div>
                        ))}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-40">Loading...</div>
        )}

        {isEditModalOpen && (
          <Modal
            size="lg"
            show={isEditModalOpen}
            onHide={() => setIsEditModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Edit Asset"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AssetForm
                asset={currentAsset}
                currentProject={currentAsset.project}
                closeFormModal={() => setIsEditModalOpen(false)}
              />
            </Modal.Body>
          </Modal>
        )}
        {isDuplicateModalOpen && (
          <Modal
            size="lg"
            show={isDuplicateModalOpen}
            onHide={() => setIsDuplicateModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Duplicate Asset"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AssetForm
                asset={duplicatedAsset}
                duplicating={true}
                closeFormModal={() => setIsDuplicateModalOpen(false)}
              />
            </Modal.Body>
          </Modal>
        )}
        {isNoteModalOpen && (
          <Modal
            size="lg"
            show={isNoteModalOpen}
            onHide={() => setIsNoteModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Add Note"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleAddNoteSubmit}>
                <Form.Group controlId="noteText">
                  <Form.Control
                    as="textarea"
                    className="mb-2"
                    rows={5}
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Add Note
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        )}
        {isCheckOutModalOpen && (
          <Modal
            size="lg"
            show={isCheckOutModalOpen}
            onHide={() => setIsCheckOutModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Check Out"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleHistorySubmit}>
                <Form.Group controlId="location">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={historyFormData.location}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter location"
                  />
                </Form.Group>

                <Form.Group controlId="recepient" className="mt-3">
                  <Form.Label>Recipient</Form.Label>
                  <Form.Control
                    type="text"
                    name="recepient"
                    value={historyFormData.recepient}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter recipient"
                    required
                    isInvalid={!historyFormData.recepient}
                  />
                  <Form.Control.Feedback type="invalid">
                    Recipient is required.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="deliveredTo" className="mt-3">
                  <Form.Label>Delivered To</Form.Label>
                  <Form.Control
                    type="text"
                    name="deliveredTo"
                    value={historyFormData.deliveredTo}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter delivered to"
                  />
                </Form.Group>
                <Form.Group controlId="company" className="mt-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={historyFormData.cpmpany}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter company"
                  />
                </Form.Group>
                <Form.Group controlId="phone" className="mt-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={historyFormData.phone}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter hone"
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={historyFormData.email}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter mail"
                  />
                </Form.Group>

                {/* <Form.Group controlId="dueDate" className="mt-3">
                  <Form.Label>Due Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="dueDate"
                    value={historyFormData.dueDate}
                    onChange={handleHistoryInputChange}
                  />
                </Form.Group> */}

                <Form.Group controlId="note" className="mt-3">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="note"
                    value={historyFormData.note}
                    onChange={handleHistoryInputChange}
                    placeholder="Enter note"
                    required
                    isInvalid={!historyFormData.note}
                  />
                  <Form.Control.Feedback type="invalid">
                    Note is required.
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="mt-4 d-flex justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      !historyFormData.recepient || !historyFormData.note
                    }
                  >
                    Add History
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        )}
        {/* {isCheckInModalOpen && (
          <Modal
            size="lg"
            show={isCheckInModalOpen}
            onHide={() => setIsCheckInModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Check In"}</Modal.Title>
            </Modal.Header>
            <Modal.Body></Modal.Body>
          </Modal>
        )} */}
        {isChangelogModalOpen && (
          <Modal
            size="lg"
            show={isChangelogModalOpen}
            onHide={() => setIsChangelogModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Change Log"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {currentAsset.changeLog.length > 0 ? (
                  currentAsset.changeLog.map((log, index) => (
                    <div
                      key={index}
                      className="mb-4 p-4 border border-gray-200 rounded shadow-sm"
                    >
                      <DisplayCreatedFields data={log} />
                      <div className="border-t border-gray-300 pt-2">
                        {Object.entries(log.fields).map(
                          ([field, changes], i) => (
                            <div key={i} className="mb-2">
                              <strong>{field}</strong>:<br />
                              <span className="text-red-600">
                                Old: {JSON.stringify(changes.oldValue)}
                              </span>
                              <br />
                              <span className="text-green-600">
                                New: {JSON.stringify(changes.newValue)}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-500 py-8">
                    No changes to show in the changelog.
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
};

export default AssetView;
