import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Form,
  FormControl,
  Dropdown,
  Modal,
  Button,
  Navbar,
} from "react-bootstrap";
import { ProjectContext } from "../providers/ProjectContext";
import AssetForm from "./AssetForm";
import { fetchAssetsByProject, fetchAllAssets } from "../services/apiService";

function AssetsList() {
  const navigate = useNavigate();

  const { currentProject, setCurrentProject, assetList, setCurrentAsset } =
    useContext(ProjectContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [assetsPerPage, setAssetsPerPage] = useState(10);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const [assets, setAssets] = useState([]); // Add this line

  const handleAssetClick = (asset) => {
    setCurrentAsset(asset);
    navigate("/asset/" + asset._id);
  };

  const handleNewAsset = () => {
    setIsFormModalOpen(true);
  };

  const backToProjects = () => {
    setCurrentProject(null);
    navigate(currentProject ? "/projects-list" : "/assets-list", {
      replace: true,
    });
  };

  useEffect(() => {
    if (currentProject && currentProject._id) {
      fetchAssetsByProject(currentProject._id)
        .then((assets) => {
          setAssets(assets);
          console.log("assets", assets);
        })
        .catch((error) => {
          console.error(
            `Failed to fetch assets for project ${currentProject._id}`,
            error
          );
        });
    } else {
      setAssets(assetList);
      console.log("assets", assetList);
    }
  }, [currentProject, assetList]);

  // Filtering assets based on search term
  const filteredAssets = assets.filter((asset) => {
    const searchTerms = searchTerm.toLowerCase().split(",");

    return searchTerms.some((term) => {
      term = term.trim(); // Remove leading and trailing spaces
      return (
        asset.title.toLowerCase().includes(term) ||
        asset.keyNumber.toLowerCase().includes(term) ||
        asset.vandalId.toLowerCase().includes(term) ||
        asset.project.name.toLowerCase().includes(term) ||
        asset.project.code.toLowerCase().includes(term) ||
        asset.category.toLowerCase().includes(term) ||
        asset.producer.toLowerCase().includes(term)
      );
    });
  });

  // Pagination logic
  const indexOfLastAsset = currentPage * assetsPerPage;
  const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
  const currentAssets = filteredAssets.slice(
    indexOfFirstAsset,
    indexOfLastAsset
  );

  const totalPages = Math.ceil(filteredAssets.length / assetsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleAssetsPerPageChange = (e) => {
    setAssetsPerPage(Number(e));
    setCurrentPage(1); // Reset to first page with new page size
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Render pagination with limited visibility
  const renderPagination = () => {
    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 || // First page
        number === totalPages || // Last page
        (number >= currentPage - 1 && number <= currentPage + 1) // Nearby pages
      ) {
        paginationItems.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      } else if (
        number === currentPage - 2 || // Ellipsis before current page
        number === currentPage + 2 // Ellipsis after current page
      ) {
        paginationItems.push(
          <Pagination.Ellipsis key={`ellipsis-${number}`} disabled />
        );
      }
    }

    return (
      <Pagination className="justify-content-start mb-0">
        {paginationItems}
        <Pagination.First onClick={() => paginate(1)} />
        <Pagination.Prev onClick={() => paginate(currentPage - 1)} />
        <Pagination.Next onClick={() => paginate(currentPage + 1)} />
        <Pagination.Last onClick={() => paginate(totalPages)} />
      </Pagination>
    );
  };

  return (
    <div className="bg-white shadow-md rounded p-6">
      <Navbar className="shadow-sm w-full bg-white" expand="lg" fixed="top">
        <div className="pb-1 pt-20 w-full px-6">
          <div className="flex flex-row justify-between">
            {currentProject ? (
              <div className="flex flex-row justify-between gap-4">
                <Button onClick={backToProjects} className="mb-4">
                  Back
                </Button>
                <h2 className="text-2xl font-bold mb-4">
                  {currentProject.code ? currentProject.code : "#####"} -{" "}
                  {currentProject.name}
                </h2>
              </div>
            ) : (
              <h2 className="text-2xl font-bold mb-4">All Assets</h2>
            )}
            <Button onClick={handleNewAsset} className="mb-4">
              Add New Asset
            </Button>
          </div>
          <div className="flex flex-row justify-between items-center mb-4">
            <div className="flex justify-between items-center w-full">
              <Form className="w-[80%]">
                <FormControl
                  type="text"
                  placeholder="Search Assets"
                  className="mr-sm-2"
                  onChange={handleSearchTermChange}
                />
              </Form>
            </div>
            <div className="flex flex-row gap-3 align-middle items-center">
              {renderPagination()}
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {assetsPerPage}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[10, 20, 30, 40, 50].map((number) => (
                    <Dropdown.Item
                      as="button"
                      key={number}
                      onClick={() => handleAssetsPerPageChange(number)}
                    >
                      {number}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div>Per Page</div>
            </div>
          </div>
        </div>
      </Navbar>
      <div className="px-6 pb-6 mt-60">
        {currentAssets.length > 0 ? (
          <ul className="list-none">
            {currentAssets.map((asset, index) => (
              <li
                key={index}
                className="cursor-pointer p-2 hover:bg-gray-200"
                onClick={() => handleAssetClick(asset)}
              >
                {asset.category && asset.category.trim() !== "" && (
                  <div>
                    <strong>Category:</strong> {asset.category}
                  </div>
                )}
                {asset.title && asset.title.trim() !== "" && (
                  <div>
                    <strong>Title:</strong> {asset.title}
                  </div>
                )}
                {asset.vandalId && asset.vandalId.trim() !== "" && (
                  <div>
                    <strong>Vandal ID:</strong> {asset.vandalId}
                  </div>
                )}
                {asset.keyNumber && asset.keyNumber.trim() !== "" && (
                  <div>
                    <strong>Key Number:</strong> {asset.keyNumber}
                  </div>
                )}
                {asset.project &&
                  asset.project.name &&
                  asset.project.name.trim() !== "" && (
                    <div>
                      <strong>Project:</strong>{" "}
                      {asset.project.code && asset.project.code.trim() !== ""
                        ? asset.project.code
                        : "#####"}{" "}
                      - {asset.project.name}
                    </div>
                  )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="italic">There are no assets.</div>
        )}
      </div>
      {isFormModalOpen && (
        <Modal
          size="lg"
          show={isFormModalOpen}
          onHide={() => setIsFormModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Add Asset"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AssetForm
              asset={null}
              closeFormModal={() => setIsFormModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default AssetsList;
