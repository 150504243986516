import React from "react";
import Select from "react-select";

const CustomSelect = ({
  field,
  form,
  options,
  isMulti,
  isDisabled,
  onChange,
  ...props
}) => {
  const handleChange = (selectedOption) => {
    const value = isMulti
      ? selectedOption
        ? selectedOption.map((option) => option.value)
        : []
      : selectedOption?.value;
    form.setFieldValue(field.name, value);

    if (onChange) {
      onChange(selectedOption, form.setFieldValue);
    }
  };

  const selectedValue = isMulti
    ? options.filter((option) => field.value.includes(option.value))
    : options.find((option) => option.value === field.value) || null;

  return (
    <Select
      {...field}
      {...props}
      options={options}
      value={selectedValue}
      onChange={handleChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      closeMenuOnSelect={!isMulti} // Closes menu only if it's not a multi-select
    />
  );
};

export default CustomSelect;
